import React, { Component } from 'react';
import { Button, Form, Message, Icon, Dropdown } from 'semantic-ui-react';
import AuthService from '../services/AuthService';
import { apiRequest, userProfile } from '../services/ApiClient';
import TermsOfService from './TermsOfService';
import fastSort from 'fast-sort';

class SignupForm extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      displayButton: true,
      email: '',
      password: '',
      name: '',
      organizations: {},
      organization: '',
      roles: {},
      role: '',
      errors: {}
    };
    this.Auth = new AuthService();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleDropdownChange(e, d) {
    this.setState({ [d.name]: d.value });
  }

  getRefereeSocietyQuery() {
    // pathname = "/signup/referee_society"
    const path_parts = window.location.pathname.split('/');
    if (path_parts[2]) {
      return path_parts[2];
    } else {
      return null;
    }
  }

  getOrganizations() {
    const referee_society_query = this.getRefereeSocietyQuery();
    apiRequest(this.Auth.links().organizations, 'GET', { referee_society: referee_society_query }).then(response => {
      let organizations = this.state.organizations;
      response.data.map(organization => (organizations[organization.id] = organization));
      this.setState({ organizations });
    });
  }

  getRoles() {
    apiRequest(this.Auth.links().roles, 'GET').then(response => {
      let roles = this.state.roles;
      response.data.map(role => (roles[role.id] = role));
      this.setState({ roles });
    });
  }

  organizationType() {
    const selected_role = this.state.roles[this.state.role].name;
    let organization_type;
    switch (selected_role) {
      case 'Referee':
        organization_type = 'referee_society';
        break;
      case 'Referee Society':
        organization_type = 'referee_society';
        break;
      case 'Union':
        organization_type = 'union';
        break;
      case 'Club':
        organization_type = 'club';
        break;
    }

    return organization_type;
  }

  organizationOptions() {
    if (this.state.role == '') {
      return null;
    }
    if (Object.values(this.state.roles).size == 0) {
      return null;
    }

    const organization_type = this.organizationType();
    const filtered_organizations = Object.values(this.state.organizations).filter(organization => {
      return organization[organization_type] == true;
    });

    const sorted_organizations = fastSort(filtered_organizations).asc('name');

    return Object.values(sorted_organizations).map(organization => {
      return { key: organization.id, value: organization.id, text: organization.name };
    });
  }

  roleOptions() {
    const rename = {
      'Union': 'Competition Administrator',
      'Referee Society': 'Referee Society Administrator',
      'Club': 'Club Administrator'
    };

    return Object.values(this.state.roles).map(role => {
      if (rename[role.name]) {
        return { key: role.id, value: role.id, text: rename[role.name] };
      }
      return { key: role.id, value: role.id, text: role.name };
    });
  }

  handleSubmit() {
    this.setState({ loading: true });
    if (!this.validateForm()) {
      this.setState({ loading: false });
      return;
    }

    this.createUser()
      .then(response => {
        this.createUserRoleOrganization(response.data).then(response => {
          this.setState({
            success_message: 'Thanks for signing up! You will receive an email when your membership has been approved.',
            loading: false,
            displayButton: false
          });
        });
      })
      .catch(err => {
        console.log(err);
        if (err.email && err.email.detail == 'has already been taken') {
          this.setState({ error_message: 'That email address already exists!', loading: false });
        } else {
          this.setState({
            error_message: this.unknownError(),
            loading: false
          });
        }
      });
  }

  createUser() {
    const user = {
      email: this.state.email,
      password: this.state.password,
      name: this.state.name,
      time_zone: this.state.organizations[this.state.organization].time_zone
    };

    return apiRequest(this.Auth.links().create_user, 'POST', { model: 'user' }, user);
  }

  createUserRoleOrganization(user) {
    const user_role_organization = {
      user: { id: user.id },
      organization: { id: this.state.organization },
      role: { id: this.state.role }
    };

    return apiRequest(
      this.Auth.links().create_user_role_organization,
      'POST',
      { model: 'user_role_organization' },
      user_role_organization
    );
  }

  validateForm() {
    let error_messages = [];
    let errors = {};
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email)) {
      error_messages.push('Please enter a valid email address.');
      errors.email = true;
    }
    if (!/^\S+\ +\S/.test(this.state.name)) {
      error_messages.push('Please enter your first and last name.');
      errors.name = true;
    }
    if (this.state.password.length < 5) {
      error_messages.push('Please enter a password with at least 5 characters.');
      errors.password = true;
    }
    if (this.state.role == '') {
      error_messages.push('Please select a role.');
      errors.role = true;
    }
    if (this.state.organization == '') {
      error_messages.push('Please select an organization.');
      errors.organization = true;
    }

    this.setState({ error_message: error_messages.join(' '), errors });
    return Object.values(errors).length == 0;
  }

  componentDidMount() {
    this.getOrganizations();
    this.getRoles();
  }

  unknownError() {
    return (
      <span>
        An unknown error occured. Please contact <a href="mailto:help@refup.io">help@refup.io</a>
      </span>
    );
  }

  render() {
    return (
      <div>
        <Form size="large">
          <Form.Input
            name="email"
            fluid
            icon="mail"
            iconPosition="left"
            placeholder="E-mail Address"
            value={this.state.email}
            onChange={e => this.handleChange(e)}
            error={this.state.errors.email}
          />
          <Form.Input
            name="name"
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Name"
            value={this.state.name}
            onChange={e => this.handleChange(e)}
            error={this.state.errors.name}
          />
          <Form.Input
            name="password"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            value={this.state.password}
            onChange={e => this.handleChange(e)}
            error={this.state.errors.password}
          />
          <Form.Field error={this.state.errors.role}>
            <div
              className="ui fluid left icon input"
              style={{
                border: '1px solid rgba(34,36,38,.15)',
                borderRadius: '.28571429rem',
                lineHeight: '1.21428571em',
                padding: '.67857143em 1em .67857143em 2.67142857em'
              }}
            >
              I am a&nbsp;
              <Dropdown
                name="role"
                inline
                style={{ minWidth: '200px' }}
                icon={<Icon name="dropdown" style={{ float: 'right' }} />}
                options={this.roleOptions()}
                value={this.state.role}
                onChange={(e, d) => this.handleDropdownChange(e, d)}
              />
              <Icon name="cog" />
            </div>
          </Form.Field>
          <Form.Field error={this.state.errors.organization}>
            <div
              className="ui fluid left icon input"
              style={{
                border: '1px solid rgba(34,36,38,.15)',
                borderRadius: '.28571429rem',
                lineHeight: '1.21428571em',
                padding: '.67857143em 1em .67857143em 2.67142857em'
              }}
            >
              With&nbsp;
              <Dropdown
                name="organization"
                placeholder="Organization"
                inline
                search
                upward
                style={{ minWidth: '200px' }}
                icon={<Icon name="dropdown" style={{ float: 'right' }} />}
                options={this.organizationOptions()}
                onChange={(e, d) => this.handleDropdownChange(e, d)}
                value={this.state.organization}
              />
              <Icon name="group" />
            </div>
          </Form.Field>

          {this.state.displayButton && (
            <Button primary fluid size="large" loading={this.state.loading} onClick={() => this.handleSubmit()}>
              Sign Up!
            </Button>
          )}
        </Form>
        {this.state.error_message && <Message error>{this.state.error_message}</Message>}
        {this.state.success_message && <Message success>{this.state.success_message}</Message>}
        <TermsOfService />
      </div>
    );
  }
}

export default SignupForm;
