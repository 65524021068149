import React, { Component } from 'react';
import { Button, Form, Message, Divider } from 'semantic-ui-react';
import { Switch, Route } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { apiRequest, userProfile, fetchUserProfile, setApiAuthorization } from '../services/ApiClient';
import TermsOfService from './TermsOfService';

class LoginForm extends Component {
  constructor() {
    super();
    this.state = { email: '', password: '', loading: false };
    this.Auth = new AuthService();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    if (this.state.email.length === 0 || this.state.password.length === 0) {
      this.setState({ error_message: 'Please enter your email and password.' });
      return;
    }

    this.setState({ loading: true });

    this.Auth.login(this.state.email, this.state.password)
      .then(res => {
        this.setupUser();
      })
      .catch(err => {
        console.log(err);
        this.setState({ error_message: 'Invalid email or password.', loading: false });
      });
  }

  handleRequestPasswordReset(e) {
    e.preventDefault();

    if (this.state.email.length === 0) {
      this.setState({ error_message: 'Please enter your email.' });
      return;
    }

    this.Auth.requestPasswordReset(this.state.email)
      .then(res => {
        this.setState({
          error_message: null,
          success_message: 'Please check your email to complete resetting your password.'
        });
      })
      .catch(err => {
        this.setState({
          error_message: 'No account matching that email was found.'
        });
      });
  }

  handleResetPassword(e) {
    e.preventDefault();

    this.setState({ loading: true });

    this.Auth.resetPassword(this.props.match.params['token'], this.state.password)
      .then(res => {
        this.setupUser();
      })
      .catch(err => {
        alert(err);
        this.setState({ loading: false });
      });
  }

  setupUser() {
    setApiAuthorization(this.Auth.getToken());
    fetchUserProfile().then(profile => {
      this.setState({ loading: false });

      if (profile.pending) {
        this.setState({ error_message: 'This account is still pending approval.' });
        return;
      }

      if (this.props.onAuthentication) {
        this.props.onAuthentication(profile);
      }

      const return_path = this.props.location?.state?.from?.pathname;
      const return_search = this.props.location?.state?.from?.search;
      if (return_path) {
        this.props.history.push(return_path + return_search);
      } else {
        this.props.history.push('/');
      }
    });
  }

  componentDidMount() {
    this.Auth.logout();
    if (this.props.onAuthentication) {
      this.props.onAuthentication(null);
    }
  }

  loginInputs() {
    return (
      <div>
        <Form.Input
          name="email"
          fluid
          icon="mail"
          iconPosition="left"
          placeholder="E-mail Address"
          value={this.state.email}
          onChange={e => this.handleChange(e)}
        />
        <Form.Input
          name="password"
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          value={this.state.password}
          onChange={e => this.handleChange(e)}
        />

        <Button primary fluid size="large" loading={this.state.loading} onClick={e => this.handleLogin(e)}>
          Login
        </Button>
        <Divider />
        <Button fluid size="medium" onClick={e => this.handleRequestPasswordReset(e)}>
          Reset Password
        </Button>
      </div>
    );
  }

  passwordResetInputs() {
    return (
      <div>
        <Form.Input
          name="password"
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          value={this.state.password}
          onChange={e => this.handleChange(e)}
        />

        <Button primary fluid size="large" loading={this.state.loading} onClick={e => this.handleResetPassword(e)}>
          Update Password
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Form size="large">
          <Switch>
            <Route path="/password_resets" render={() => this.passwordResetInputs()} />
            <Route render={() => this.loginInputs()} />
          </Switch>
        </Form>

        {this.state.error_message && <Message error>{this.state.error_message}</Message>}
        {this.state.success_message && <Message success>{this.state.success_message}</Message>}
        <TermsOfService />
      </div>
    );
  }
}

export default LoginForm;
